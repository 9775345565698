import { render, staticRenderFns } from "./KeFu.vue?vue&type=template&id=fa555812&scoped=true&"
import script from "./KeFu.vue?vue&type=script&lang=ts&"
export * from "./KeFu.vue?vue&type=script&lang=ts&"
import style0 from "./KeFu.vue?vue&type=style&index=0&id=fa555812&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa555812",
  null
  
)

export default component.exports