



































// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: {}
})
export default class FeedBackDialog extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) dgFdVisible!: boolean;
  @Provide() form: any = {
    mobile: "",
    fd_content:""
  };
  @Provide() rules: any = {
    mobile: [
      { required: true, message: "请输入登录手机号", trigger: "blur" }
    ]
  };
  created() {
  }
  doFeedBack(fdRule:any) {
    let utils = (this as any).$utils;
    let mobile = this.form.mobile.trim();
    let fd_content = this.form.fd_content.trim();
    if(!utils.checkMobile(mobile)){
      this.$message.error('请输入正确的联系方式');
      return;
    }

    if(fd_content == '') {
      this.$message.error('请输入投诉或建议内容');
      return;
    }

    let p = {
      m: "user.addFeedBack",
      mobile: mobile,
      fd_content: fd_content
    };
    
    (this.$refs[fdRule] as any).validate((valid: boolean) => {
      if (valid) {
        (this as any).$ajax.httpPost(
          "/api/api/router",
          p,
          (res: any) => {
            this.$emit("closeFdDialog");
            if (res.data.code === 0) {
              this.$message({
                message: res.data.msg,
                type: "success"
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error"
              });
            }
          },
          (err: any) => {
            console.log(err);
          }
        );
      }
    });
  }
}
