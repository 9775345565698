















// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LeftCat extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) leftCatVisible!: boolean;
  @Provide() filterNavItemActive:Number = 0;
  @Prop(Array) cat_list!:any;
  created(){
    // this.loadGoodsCat();
  }
  loadGoodsCat() {
    let p = {
        m: "goods.getCategoryList"
    };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      p,
      (res: any) => {
        if (res.data.code === 0) {          
          this.cat_list = res.data.data.cat_list;
        } else {
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  goToAnchor(id:any, index:Number) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth"
      });
      this.filterNavItemActive = index;
    }
}
