






























































































































// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import LayoutHeader from "../layout/LayoutHeader.vue";
import LayoutFooter from "../layout/LayoutFooter.vue";
import LeftCat from "../layout/LeftCat.vue";
import KeFu from "../layout/KeFu.vue";
import NoticeDialog from "../../components/NoticeDialog.vue";
import { State, Getter, Mutation, Action } from "vuex-class";

@Component({
  components: { LayoutHeader,LayoutFooter,NoticeDialog,LeftCat,KeFu },
})
export default class Index extends Vue {
  // 公告
  @Provide() run_notice: String = "";
  @Provide() index_notices: String = '';
  // 配置信息
  // @Provide() siteConfig: any = [];
  @Getter('config') siteConfig: any;
  @Provide() cat_list: any = [];
  @Provide() dgNoticeVisible: boolean = false;
  @Provide() leftCatVisible: boolean = false;
  @Provide() noticeData: any = {};
  
  created() {
    let utils = (this as any).$utils;
    utils.navMobile(1,"0");
    this.getNotice();
    // this.getSystemConfig();
    this.getCatGoodsList('');
  }
  mounted(){
    window.addEventListener('scroll',this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  }
  handleScroll(){
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
    if(scrollTop > 200) {
      this.leftCatVisible = true;
    } else {
      this.leftCatVisible = false;
    }
    
  }
  // 跳转到商品检索页面
  searchGoods(kw:any): void {
    this.$router.push({ name: 'categoryList', query: { kw: kw }});
  }
  loadGoodsList(kw:String) {
    this.getCatGoodsList(kw);
  }
  // 获取公告信息
  getNotice() {
    let utils = (this as any).$utils;
    let forms = {
        m: "notice.getNoticelist"
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          let notice_list = res.data.data.notice_list;
          notice_list.forEach((notice:any,index:Number) => {
            if(notice.type == 1) {
              this.run_notice = notice.content;
            }
            if(notice.type == 2) {
              let notice_flg = localStorage.getItem('notice_flg');
              notice_flg = utils.praseStrEmpty(notice_flg);
              if(notice.first_open == 1 && notice_flg == "1") {
                this.dgNoticeVisible = false;
              } else {
                this.dgNoticeVisible = true;
              }
              localStorage.setItem('notice_flg',"1");
              this.noticeData = notice;
            }
            if(notice.type == 3) {
              let content = utils.praseStrEmpty(notice.content);
              this.index_notices = content;
            }
          })
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  closeNoticeDialog() {
    this.dgNoticeVisible = false;
  }
  // 获取配置信息
  getSystemConfig() {
    console.log('index_config');
    let forms = {
        m: "base.getSystemConfig",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.siteConfig = res.data.data.config;
          document.title = this.siteConfig.webtitle;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 获取商品分类及商品
  getCatGoodsList(kw: String) {
    let forms = {
        m: "goods.getCategoryGoodsList",
        kw: kw
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.cat_list = res.data.data.cat_goods_list;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  // 跳转到商品详细页面
  toGoods(goods_id:any): void {
    // let p:o = ;
    // this.$router.push({ name: 'goods', params: { id: goods_id }});
    this.$router.push({ name: 'goods', query: { id: goods_id }});
  }
}
