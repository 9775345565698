

































































// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";
import { State, Getter, Mutation, Action } from "vuex-class";
import FeedBackDialog from "../../components/FeedBackDialog.vue";
import TouristDialog from "../../components/TouristDialog.vue";

@Component({
  components: {FeedBackDialog,TouristDialog}
})
export default class KeFu extends Vue {
    @Prop() siteConfig!:[];
    // 是否打开反馈Dialog页面
    @Provide() dgFdVisible: boolean = false;
    @Provide() mouseenter:boolean = false;
    @Getter('config') config: any;
    created(){
        console.log('config',this.config);
    }
    goToAnchor(id:any) {
        document.querySelector(id).scrollIntoView({
            behavior: "smooth"
        });
    }
    mouseover(e:any) {
        this.mouseenter = true;
    }
    mouseleave(e:any) {
        this.mouseenter = false;
    }
    feedBack(e:any) {
        this.dgFdVisible = true;
    }
    closeFdDialog () {
        this.dgFdVisible = false;
    }
}
